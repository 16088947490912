@import '../../styles/main.module.scss';

@keyframes rotate_animation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.modal_main_container {

    z-index: $modal_main_container;
    
    position: fixed;
    top: 0;
    left: 0;
    
    padding: $big;

    width: 100%;
    min-height: 60px;
    height: fit-content;

    display: flex;
        justify-content: center;
        align-items: center;
}

.on {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    transition: all .2s;
}

.off {
    transform: scale(0.8);
    visibility: hidden;
    opacity: 0;

    transition: all .2s;
}

.modal {

    padding: $big;

    width: 100%;
    max-width: 375px;
    min-height: 60px;

    border-radius: $mid;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $big;

    background-color: black;
    
    box-shadow: $small_shadow;
}

.image_notif {
    width: 36px;
    height: 36px;
}


.image_notif_rotate {
    width: 35px;
    height: auto;
    animation-name: rotate_animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.content {
    font-size: .8rem;
    font-weight: 400;
    color: $white;
    text-align: left;
    line-height: 1rem;
    letter-spacing:0ch;
}

.close_modal {
    cursor: pointer;

    padding: 4px 12px;

    font-size: .6rem;
    font-weight: 400;
    
    color: white;

    border-radius: $big;
    background-color: rgb(76, 76, 76);
    
    &:hover {
        background-color: $primary_hover;
    }
}
