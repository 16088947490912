@import '../../styles/main.module.scss';

.header {
    position: sticky;
    top: 0;
    z-index: $header;

    width: 100%;
    height: 10vh;

    padding: 30px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: $dark_black;

    
    @include tablet {
        padding: 15px;
    }
}

.shadow_on {
    box-shadow: $small_shadow;

    transition: all .4s;
}

.shadow_off {
    box-shadow: none;

    transition: all .4s;
}

.logo {
    display: block;

    @include tablet {
        display: none;
    }
}

.icono {
    display: none;

    @include tablet {
        display: block;
    }
}

.user_data_container {
    position: relative;
    padding: 8px;

    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;

    border-radius: 8px;
}

.user_name_role {
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

    font-size: 14px;
    font-family: 'Light';
    color: white;
}

.user_image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: solid 1px grey;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include phone {
        display: none;
    }
}

.user_popup_menu {
    z-index: $user_popup_menu;
    cursor: pointer;

    padding: 0;
    position: absolute;

    width: 170px;
    // height: 80px;

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;


    border-radius: 12px;
    border: solid 1px $light_black;
    background: $black;

    box-shadow: $tiny_shadow;
}

.user_popup_menu_item {
    padding: $big;

    width: 100%;

    display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
    
    border-bottom: dotted 1px rgb(80, 80, 80);

    p {
        font-size: 14px;
        font-family: 'Ultralight';
        color: $low_white;
    }

    
    &:hover {
        background: $dark_black;
        
        &:first-child {
            border-bottom: none;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
        &:last-child {
            border-bottom: none;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}

.on {
    top: 50px;
    right: 0;

    visibility: visible;
    opacity: 1;
    
    transition: all .3s;
}

.off {
    top: 0;
    right: 0;

    visibility: hidden;
    opacity: 0;
    
    transition: all .3s;
}

.close_session_modal {
    z-index: $close_session_modal;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: grid;
    place-content: center;

    background: rgba(12 12 12/0.8);

    p {
        color: orange;
    }
}

.modal_openclose {
    width: 340px;
    // height: 230px;

    padding: $big;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    border: solid 1px $light_black;
    border-radius: $mid;
    background: $black;
}

.modal_openclose_text {
    padding-bottom: 30px;
    width: 100%;

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

    // border-bottom: dotted 1px $white;

    p {
        font-size: 14px;
        color: $white;
    }
}

.modal_openclose_message {
    font-size: 15px;
    font-family: 'Light';
}

.modal_openclose_user_email {
    font-size: 15px;
    font-family: 'Light';
}

.modal_openclose_buttons {
    padding-top: 30px;
    

    display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        gap: 28px;
}