$tiny: 4px;
$small: 8px;
$mid: 12px;
$normal: 14px;
$large: 16px;
$big: 18px;
$mega: 20px;
$ultra: 23px;
$hiper: 26px;
$mongo: 50px;
$god: 100px;

$small_text: 14px;
$mid_text: 16px;
$text: 18px;
$big_text: 20px;
$mega_text: 23px;
$ultra_text: 25px;
$hiper_text: 28px;

//ELEMENT AND TEXT COLORS
$white: rgb(255, 255, 255);
$dark_white: rgb(120, 120, 120);

$low_white: rgb(193, 196, 202);

$light_black: rgb(65, 65, 65);
$black: #202528;
$dark_black: #151619;

// $primary: #fa114f;
$primary: #FF5500;
$primary_hover: #c60035;
$secondary: #00aeff;
$secondary_hover: #007ce2;
$third: #1279d8;
$third_menu: #0463bc;
$selected: #004a90;
$placeholder: #9e9e9e;

$alt:  #42a4c5;
$danger: rgb(255, 0, 47);
$ok: #007ce2;

//BACKGROUND
$primary_background: white;
$light_background: rgb(0 174 255/.1);



$dark_text: #777;
$white_text: #ddd;

$blue: rgb(0, 149, 255);


$alt: #00a2ff;
$alt_hover: #005db4;
$alt_hover: #007ce2;

$call_to_action: #346bf3;


// GRADIENT COLOR FOR BUTTONS
$primary_gradient: linear-gradient(to right, $primary, $primary_hover);
$primary_dark_gradient: linear-gradient(to right, $black, black);
// $secondary: #ff56a2;


// BACKGROUNDS
$primary_background:  #3a2e1e;
$secondary_background: #2d2d2d;
$third_background: #181513;

$ultra_light_background: rgb(241, 241, 241);
$light_background: rgb(233, 233, 233);
$mid_background: rgb(180, 180, 180);
$primary_radial_gradient_background: radial-gradient($black, $dark_black);
$dimmed_background: linear-gradient(to left, rgb(194, 119, 20), rgb(237, 159, 90));

$light_title_background: linear-gradient(45deg, rgb(252 252 252), rgb(248 248 248));


//SHADOWS
$tiny_shadow: 3px 3px 12px 4px rgba( 0 0 0 /0.1);
$small_shadow: 0 0 16px 3px rgba( 0 0 0 /0.25);
$publication_shadow: 0 8px 16px -4px rgba( 0 0 0 /0.1);
$dark_shadow: 0 8px 16px -4px rgba( 0 0 0 /0.9);

$small_shadow_svg: drop-shadow(0px 4px 6px rgb(0 0 0/.4));


$default_button: rgb(140, 140, 140);
$default_button_hover: rgb(92, 92, 92);



//Z INDEXES

//botones de paginacion de lista de publicaciones
$prevnext: 10;

//menu pop up del perfil de usuario en el header
$user_popup_menu: 11;

//notificaciones
$modal_main_container: 901;

//ventana modal de cerrar sesión
$close_session_modal: 100;

$editors_window: 200;

$header: 900;

$popupstatics: 1000;