@import '../sidebar.module.scss';


.players_list {
    width: 100%;
    
    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        gap: $big;

    // display: grid;
    //     grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    //     gap: $big;
}

.players_card {
    cursor: pointer;
    position: relative;

    padding: $big $big $tiny;
    width: 350px;
    min-height: 540px;

    display: grid;
    place-content: center;
    gap: $small;

    border-radius: $big;
    background-color: $black;
    box-shadow: $tiny_shadow;

    transition: ease all .3s;

    @include tablet {
        width: 100%;
    }
    
    &:hover {
        transform: scale(1.02);
    }
    
}


.modal_block {
    max-width: 400px;
    height: fit-content;
    padding: 40px;
}