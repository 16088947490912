@import '../sections.module.scss';


.title_container {
    justify-content: space-between;
}

.main_subtitle {
    font-size: 20px,
}

.pass_form {

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;

}

.input_eye {
    margin: $mid 0;
    width: 400px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: $small;

    @include tablet {
        width: 100%;
    }
}




.input {
    width: 300px;
    font-size: 16px;

    @include tablet {
        width: 100%;
    }
}

.textarea {
    font-size: 14px;
    height: 180px;
}


.block {
    padding: $mongo;
    width: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &:hover {
        transform: none;
    }

    @include tablet {
        width: 100%;
    }
}

.image_block {

    cursor: pointer;

    width: fit-content;
    height: fit-content;
    min-height: 120px;

    padding: $mongo;

    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    gap: 14px;

    font-size: 18px;
    font-family: 'Light';
    letter-spacing: -0.5px;
    color: $white;

    border-radius: $big;
    background-color: $black;

    box-shadow: $tiny_shadow;
}

.image_uploaded {
    width: 200px;
    height: 200px;

    border-radius: 50%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
