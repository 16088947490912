@font-face {
    font-family: 'Regular';
    src: url(./fonts/regular.otf);
}

.imagen {
    width: 100%;

    border-radius: 6px;
}

.epigrafe {
    margin: 18px 0;
    padding: 18px 0;
    font-size: 15px;
    font-family: 'Regular';
    font-style: italic;
    color: #007ce2;

    border-top: dotted 1px #007ce2;
    border-bottom: dotted 1px #007ce2;
}