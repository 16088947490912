@import '../edit.module.scss';


.image_epigraph {
    width: 100%;
    padding: $big 0;

    display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: $hiper;
        
    @include tablet {
        padding: 0;

        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
}

.image_gallery_deleter {

    display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        // gap: $hiper;
        
    @include tablet {
        padding: 0;

        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
}

.title_content {
    // width: 100%;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    gap: 20px;
}

.title_epigraph {
    width: 100%;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
}

.quill_title {
    padding: $big 0;

    font-size: $text;
    font-family: 'Bold';
    letter-spacing: -0.5px;
}

.quill_group {
    width: 100%;
    height: 390px;
    
    border-radius: 10px;
    background: $white;
}

.quill_textarea {
    margin: $small;
    padding: $mid;
    width: 100%;
    height: 300px;
    
    font-size: 22px;
    color: $black;
}

.epigraph {
    padding: $mid;

    width: 100%;
    height: 110px;

    resize: none;
    
    font-size: 16px;
    font-family: 'Light';
    color: $black;
    letter-spacing: -1px;

    border-radius: 10px;
    border: none;
}

.tag {
    padding: $small $mid;
    min-width: 80px;

    font-size: 12px;
    font-family: 'Bold';
    color: $black;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    border-bottom-right-radius: $big;
    border-top-right-radius: $big;
    
    background-color: $white;

    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $small;
}

.tag_delete {
    cursor: pointer;
    position: relative;
    width: 22px;
    height: 22px;

    border-radius: 50%;
    background: $black;

    &::before {
        position: absolute;
        content: '';
        top: 3px;
        left: 9px;
        
        width: 4px;
        height: 16px;
        background: $white;

        transform: rotate(45deg);
    }
    &::after {
        position: absolute;
        content: '';
        top: 3px;
        left: 9px;
        
        width: 4px;
        height: 16px;
        background: $white;

        transform: rotate(135deg);
    }
}

.textarea {
    margin: $big 0;
    padding: $mid;
    width: 100%;
    height: 300px;

    width: 100%;
    height: 320px;

    resize: none;
    
    font-size: 16px;
    font-family: 'Light';
    color: $black;
    letter-spacing: -1px;

    border-radius: 10px;
    border: none;
}