@import '../../styles/main.module.scss';


main {
    background: $dark_black;
};

.content_container {
    padding: 0 $mid;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
}