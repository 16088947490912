@import '../sidebar.module.scss';


.banners_list {
    width: 100%;
    
    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        gap: $mega;
}

.banner_card {
    cursor: pointer;
    position: relative;

    padding: $big $big $tiny;
    width: 300px;

    display: grid;
    place-content: center;

    border-radius: $big;
    background-color: $black;
    box-shadow: $tiny_shadow;

    transition: ease all .3s;

    @include tablet {
        width: 100%;
    }
    
    &:hover {
        transform: scale(1.02);
    }
    
}

.banner_image {
    width: 100%;

    border-radius: $small;
}

.delete_icon {
    position: absolute;
    top:   10px;
    right: 10px;

    margin: 10px;
    padding: 8px;

    display: grid;
    place-content: center;

    border-radius: $small;
}

.edit_icons {
    margin: 10px;
    padding: 8px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $big;

    border-radius: $small;
}

.edit_icon {
    cursor: pointer;
    margin: $small;

    font-size: 12px;
    font-family: 'Regular';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.edit_form {
    position: absolute;
    width: 100%;
    height: 100%;
    // margin: $small;

    font-size: 16px;
    font-family: 'Regular';
    
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;

    border-radius: $big;
    background: rgba(0, 0, 0, 0.8);
}