@import '../../list.module.scss';


.block {
    max-width: 600px;
    min-height: 330px;
    position: relative;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
}

.publication_image {
    width: 100%;
    height: 180px;

    border-radius: $small;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.card_title {
    // height: 100px;
    font-size: 18px;
}


.delete_icon {
    margin: 10px;
    padding: 8px;
    
    position: absolute;
    top:   10px;
    right: 10px;

    display: grid;
    place-content: center;

    border-radius: $small;
}