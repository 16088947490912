@import '../../styles/main.module.scss';

.logo {
    padding: 22px;
}

.form_container .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
}
