@font-face {
    font-family: 'Black';
    src: url(./fonts/black.otf);
}

@font-face {
    font-family: 'Bold';
    src: url(./fonts/bold.otf);
}

@font-face {
    font-family: 'Regular';
    src: url(./fonts/regular.otf);
}

@font-face {
    font-family: 'Light';
    src: url(./fonts/light.otf);
}

@font-face {
    font-family: 'Ultralight';
    src: url(./fonts/ultralight.otf);
}
