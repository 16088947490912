@import '../sections.module.scss';



.on {
    visibility: visible;
    opacity: 1;
    
    transition: all .3s;
}

.off {
    visibility: hidden;
    opacity: 0;
    
    transition: all .3s;
}

.title_container {
    justify-content: space-between;
}

.title_block {
    flex-direction: row;
    justify-content: center;

    cursor: pointer;
    // cursor: default;

    // max-width: 260px;
    width: 100%;
    height: fit-content;
    min-height: 70px;

    padding: $big;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;

    font-size: 18px;
    font-family: 'Light';
    letter-spacing: -0.5px;
    color: $white;

    border-radius: $big;
    background-color: $black;

    box-shadow: $tiny_shadow;

    // &:hover {
    //     transform: scale(1.02);
    // }
    transition: ease all .3s;
}

.title_and_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $mid;
}


.edit_icons {
    margin: 10px;
    padding: 8px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $big;

    border-radius: $small;
}

.edit_icon {
    cursor: pointer;
    margin: $small;

    font-size: 12px;
    font-family: 'Regular';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.edit_form {
    padding: $small;
    
    position: absolute;
    width: 100%;
    height: 100%;

    font-size: 16px;
    font-family: 'Regular';
    
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $big;

    border-radius: $big;
    background: rgba(0, 0, 0, 0.8);
}


.edit_block {
    width: 100%;
    
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $small;
}

.edit_buttons {
    
    margin: $big;
    display: flex;
        flex-direction: row;
        gap: $big;
}

.input {
    width: 100%;
}

.textarea {
    font-size: 14px;
    height: 180px;
}


.info_block {
    margin: $mid;
    padding: $mongo;

    width: 100%;
    max-width: 550px;
    height: fit-content;
    min-height: 300px;

    font-size: 16px;
    font-family: 'Light';
    color: white;
    text-align: left;

    position: relative;
 

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border: solid 1px $light_black;
    border-radius: $mid;
    background: $black;
}
