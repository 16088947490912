@import '../sections.module.scss';


.main_row_content {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    
    padding: $mid;
    width: 100%;

    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $big;
}

.section_id {
    padding: $tiny;
    min-width: 28px;

    height: fit-content;

    color: $white;
    text-align: center;

    
    border-radius: $tiny;
}

.section_row {
    width: 100%;

    display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        gap: $big;
        
    @include tablet {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

.section_data {
    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        gap: $mid;

    @include tablet {
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }

}

.id_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $mid;
}

.section_title {
    // width: 100%;
    // height: 26px;
    width: 340px;
    overflow: scroll;
    
    @include tablet {
        width: 320px;
    }

    @include phone {
        width: 200px;
    }
}

.pub_date_editor {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $mid;
    
    @include tablet {
        display: none;
    }
}


.pub_icons {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $tiny;

}