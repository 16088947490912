@font-face {
    font-family: 'Plain';
    src: url(./fonts/plain.otf);
}
@font-face {
    font-family: 'Plainbold';
    src: url(./fonts/plainbold.otf);
}
@font-face {
    font-family: 'Plainlight';
    src: url(./fonts/plainlight.otf);
}
@font-face {
    font-family: 'Heavy';
    src: url(./fonts/heavy.otf);
}
@font-face {
    font-family: 'Heavy';
    src: url(./fonts/heavy.otf);
}
@font-face {
    font-family: 'HeavyItalic';
    src: url(./fonts/heavyitalic.otf);
}
@font-face {
    font-family: "Black";
    src: url(./fonts/black.otf);
}
@font-face {
    font-family: 'Bold';
    src: url(./fonts/bold.otf);
}
@font-face {
    font-family: 'Bolditalic';
    src: url(./fonts/bolditalic.otf);
}
@font-face {
    font-family: 'Semibold';
    src: url(./fonts/semibold.otf);
}
@font-face {
    font-family: 'Semibolditalic';
    src: url(./fonts/bolditalic.otf);
}
@font-face {
    font-family: 'Regular';
    src: url(./fonts/regular.otf);
}
@font-face {
    font-family: 'Italic';
    src: url(./fonts/italic.otf);
}
@font-face {
    font-family: 'Light';
    src: url(./fonts/light.otf);
}
@font-face {
    font-family: 'LightItalic';
    src: url(./fonts/lightitalic.otf);
}


//MARGINS
$big_margin: 2rem;
$mid_margin: 1.5rem;
$small_margin: 1rem;
$tiny_margin: .6rem;

// PADDINGS
$big_padding: 2rem;
$mid_padding: 1.5rem;
$small_padding: 1rem;
$tiny_padding: .6rem;
$micro_padding: .3rem;


//RADIUS
$small_radius: 6px;
$mid_radius: 12px;
$big_radius: 24px;


$tiny: 4px;
$small: 8px;
$mid: 12px;
$normal: 14px;
$large: 16px;
$big: 18px;
$mega: 20px;
$ultra: 23px;
$hiper: 26px;
$mongo: 30px;
$demigod: 38px;
$god: 60px;
$ragegod: 120px;

$small_text: 14px;
$mid_text: 16px;
$text: 18px;
$big_text: 20px;
$mega_text: 23px;
$ultra_text: 25px;
$hiper_text: 28px;


//ELEMENT AND TEXT COLORS



// ESTO SE FUE AL LIGHT MODE
$white: rgb(250, 250, 250);
$dark_white: rgb(140 140 140);
$black: #2d2d2d;
$dark_black: #000;



$primary: #ff5500;
$primary_hover: #ff0033;
$placeholder: #9e9e9e;

$dark_text: #777;
$white_text: #ddd;

$blue: rgb(0, 149, 255);

$small_radius: 2px;
$mid_radius: 4px;
$big_radius: 6px;

$alt: #00a2ff;
$alt_hover: #005db4;
$alt_hover: #007ce2;


$call_to_action: #346bf3;

// $primary: #ff0080;
// $primary_hover: #d600e1;
// $primary: #ff00e1;
// $primary_hover: #d600a4;
// $primary: #ff6500;
// $primary_hover: #e21e00;
// $primary: #00a2ff;
// $primary_hover: #007ce2;

// $alt01: rgb(63, 220, 247) 2.01%;
// $alt02: rgb(24, 144, 255) 99.51%;


// GRADIENT COLOR FOR BUTTONS
$primary_gradient: linear-gradient(to right, $primary, $primary_hover);
$primary_dark_gradient: linear-gradient(to right, $black, black);
$secondary: #ff56a2;


// BACKGROUNDS
$primary_background:  #3a2e1e;
$secondary_background: #2d2d2d;
$third_background: #181513;

$ultra_light_background: rgb(241, 241, 241);
$light_background: rgb(233, 233, 233);
$mid_background: rgb(180, 180, 180);
$primary_radial_gradient_background: radial-gradient($primary_background, $third_background);
$primary_radial_gradient_background: radial-gradient(rgb(36, 36, 36), rgb(18, 18, 18));
$dimmed_background: linear-gradient(to left, rgb(194, 119, 20), rgb(237, 159, 90));

$light_title_background: linear-gradient(45deg, rgb(252 252 252), rgb(248 248 248));

// $primary_radial_gradient_background: radial-gradient(#69e3ff, #d175ff);


//SHADOWS
$small_shadow: 0 0 16px 3px rgba( 0 0 0 /0.25);
$publication_shadow: 0 8px 16px -4px rgba( 0 0 0 /0.1);
$dark_shadow: 0 8px 16px -4px rgba( 0 0 0 /0.9);

$small_shadow_svg: drop-shadow(0px 4px 6px rgb(0 0 0/.4));


$default_button: rgb(140, 140, 140);
$default_button_hover: rgb(92, 92, 92);





// $white: rgb(10, 10, 10);
// $black: rgb(245 245 245);

// $ultra_light_background: rgb(25 25 25);
// $light_background: rgb(30 30 30);
// $mid_background: rgb(55 55 55);


//Z INDEXES

//botones de paginacion de lista de publicaciones
$prevnext: 10;

//menu pop up del perfil de usuario en el header
$user_popup_menu: 11;


//ventana modal de cerrar sesión
$close_session_modal: 100;

//barra de navegación
$header: 900;

//notificaciones
$modal_main_container: 901;

// videos de yutun
$video: 902;


@mixin smallphone{
    @media only screen and (max-width: 350px) {
        @content;
    }
}

@mixin phone{
    @media only screen and (max-width: 412px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}


.main_title {
    font-family: 'Semibold';
    font-size: 2.9em;
    color: black;
    letter-spacing: -1px;
}

.subtitle {
    font-family: 'Semibold';
    font-size: 2.2em;
    color: black;
    letter-spacing: -1px;
}

.small_title {
    font-family: 'Semibold';
    font-size: 1.6em;
    color: $alt_hover;
    letter-spacing: -1px;
    span {
        color: $primary
    }
}

.summary {
    padding: 6px 0;
    font-size: $mega;
    letter-spacing: -0.5px;
    font-family: 'Plainlight';
    color: $black;
}

.tuition {
    padding: 12px 0;
    font-family: 'Bold';
    font-size: 1.3em;
    color: black;
    letter-spacing: -0.2px;
}

.main_paragraph {
    font-family: 'Light';
    font-size: 1.1em;
}

.plain_text {
    font-family: 'Plain';
    font-size: 14.5px;
    line-height: 20px;
    color: #777;
}



.image_title_container {
    width: 100%;
    padding: 12px;

    border-bottom: dotted 1px rgb(195, 195, 195);
    // border-radius: 10px;
    // background: $light_title_background;
}

.main_title {
    position: relative;
    // font-size: 2.7rem;
    // background: linear-gradient(45deg, rgb(50, 50, 50) 0%, rgb(70, 70, 70) 23%, rgb(88, 88, 88) 40%);
    background: linear-gradient(90deg, rgb(0, 55, 114) 0%, rgb(0, 84, 174) 30%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;

    @include tablet {
        font-size:   2.5rem;
        line-height: 2.5rem;
    }

    &::after {
        position: absolute;
        content: '';
        width: 20px;
        height: 10px;
        top: 18px;
        left: -24px;
        background-color: $primary;
        // transform: rotate(45deg);
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%);

        @include tablet {
            width: 14px;
            height: 8px;
            top: 6px;
            left: -16px;
        }
    }
}

.error_title {
    padding: 4px;

    font-size: 14px;
    font-family: 'Bold';
    color: $white;
    background-color: $primary;

    border-radius: 4px;
}

    
.news {
    width: 100%;

    display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: flex-start;

}

.news_columns {
    width: fit-content;
    max-width: 760px;
    margin: 0;
    padding: 0;
    
    display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        // justify-content: flex-start;
        align-items: flex-start;
        gap: 40px 60px;
    
    @include tablet {
        width: 100vw;
    }
}


$button_height: 85px;
$line_height: 12px;

.primary_button {
    cursor: pointer;

    padding: .2rem 1.4rem;
    height: 36px;

    font-size: .9rem;
    font-family: 'Semibold';
    color: $white;

    border: none;
    border-radius: $big_radius;

    background-image: $primary_gradient;
}

.primary_button_small {
    cursor: pointer;

    padding: .6rem .8rem;
    margin-bottom: .8rem;

    font-size: .8rem;
    font-family: 'Semibold';
    color: $white;

    border: none;
    border-radius: $big_radius;

    background-image: $primary_gradient;
}

.secondary_button {
    cursor: pointer;

    padding: .2rem 1.4rem;
    height: 36px;

    font-size: .9rem;
    font-family: 'Semibold';
    color: $primary_hover;

    border: none;
    border-radius: $big_radius;

    background-color: $white;
}

.third_button {
    cursor: pointer;

    margin: .6rem 0;
    padding: .2rem .6rem;
    height: 26px;

    font-size: .8rem;
    font-family: 'Semibold';
    color: $placeholder;

    border: none;
    border-radius: $big_radius;
    background: none;
}

.primary_button_off {
    padding: .2rem 1.4rem;
    height: 36px;

    font-size: .9rem;
    font-family: 'Semibold';
    color: $white;

    border: none;
    border-radius: $big_radius;

    background: grey;

    opacity: 0.4;
}

.submit {
    cursor: pointer;
    padding: $tiny $big;

    height: 36px;

    font-size: 14px;
    font-family: 'Bold';
    color: $white;

    border: none;
    border-radius : $big;
    background-color: $call_to_action;
    
    &:hover {
        background-color: $primary_hover;
    }

    transition: all .3s;

    @include phone {
        font-size: 12px;
    }
}

.scroll_button_left {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 46%;
    left: -25px;
    width: 25px;
    height: $button_height;

    border: none;
    border-top-left-radius: $big_radius;
    border-bottom-left-radius: $big_radius;
    
    background-image: linear-gradient(to bottom, $primary, $primary_hover);

    
    &::before {
        position: absolute;
        content: "";
        top: calc($button_height / 2 - $line_height + 2px);
        width: 2px;
        height: $line_height;
        background-color: $white;
        transform: rotate(45deg);
    }
    
    &::after {
        position: absolute;
        content: "";
        top: calc($button_height / 2 - 2px);
        width: 2px;
        height: 12px;
        background-color: $white;
        transform: rotate(-45deg);
    }
    
    @include tablet {
        display: none;
    }
}

.scroll_button_right {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 46%;
    right: -25px;
    width: 25px;
    height: $button_height;

    border: none;
    border-top-right-radius: $big_radius;
    border-bottom-right-radius: $big_radius;

    background-image: linear-gradient(to bottom, $primary, $primary_hover);

    
    &::before {
        position: absolute;
        content: "";
        top: calc($button_height / 2 - $line_height + 2px);
        width: 2px;
        height: $line_height;
        background-color: $white;
        transform: rotate(-45deg);
    }
    
    &::after {
        position: absolute;
        content: "";
        top: calc($button_height / 2 - 2px);
        width: 2px;
        height: 12px;
        background-color: $white;
        transform: rotate(45deg);
    }

    @include tablet {
        display: none;
    }
}



.main_container {
    padding: $god $ragegod;

    width: 100%;
    // max-width: 1250px;

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: $mongo;

    
    @include tablet {
        padding: $mongo $big;
    }
    // @include phone {
    //     padding: $big;
    // }
}

.main_title_container {
    width: 100%;
    position: relative;
}

.categories {
    width: 100%;
    padding: $mid 0;

    display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: $small;

    // border-top: dotted 1px $alt_hover;
    border-bottom: dotted 1px $placeholder;

    // @include phone {
    //     font-size: $tiny;
    // }
}

.category {
    cursor: pointer;
    position: relative;

    width: fit-content;
    // padding: $tiny $small;
    font-size: 15px;
    letter-spacing: -0.5px;
    font-family: 'Plain';
    color: $alt_hover;
    
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        top: -8px;
        left: 0;
        background-color: $placeholder;
        transform-origin: bottom left;
        transition: transform 0.3s ease-out;

        @include tablet {
            height: 0;
        }
    }
    &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}

.split {
    width: fit-content;
    // padding: $tiny $small;
    font-size: 15px;
    letter-spacing: -0.5px;
    font-family: 'Plain';
    color: $alt_hover;
}

.category_blank {
    padding: 2px 6px;
    font-size: 13px;
    letter-spacing: -0.5px;
    font-family: 'Plainbold';
    color: rgba(0 0 0/0);
    
    border-radius: 2px;

    @include tablet {
        padding: 0 12px;
    }
}

.date {
    padding: 6px 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-family: 'Plainbold';
    color: $primary;
}

.pub_content {
    width: 74%;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: $demigod;
        gap: $god;
        
    @include tablet {
        width: 100%;
    }
}

.capital {
    margin: 0 2px;
    font-size: 50px;
    font-family: 'Black';
    color: $alt_hover;
}

.text {
    // margin: $mongo 0;

    font-size: 17px;
    line-height: 22px;
    font-family: 'Plainlight';
    text-align: left;
    color: $black;
}

.image_epigraph_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $mid;
}

.image {
    width: 100%;

    border-radius: 6px;
}

.epigraph {
    padding-bottom: $big;
    font-size: 15px;
    font-family: 'Plainlight';
    font-style: italic;
    color: $alt_hover;

    // border-top: dotted 1px $alt_hover;
    border-bottom: dotted 1px $alt_hover;
}

.dest {
    // margin: $mongo 0;
    padding: $big 0;

    max-width: 580px;
    width:100%;
    height: 100%;
    
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: $mid;

    font-size: 32px;
    font-family: 'Plainlight';
    line-height: 38px;
    // font-style: italic;
    color: $alt_hover;
    
    border-top: dotted 1px rgb(180 180 180);
    border-bottom: dotted 1px rgb(180 180 180);
    
    @include phone {
        font-size: 20px;
        line-height: 22px;
    }
}

.colon {
    position: relative;
    font-size: 74px;
    font-family: 'Bold';
    color: $primary;

    // &::before {
    //     position: absolute;
    //     content:'';

    //     width: 40px;
    //     height: 40px;

        // top: 40px;
        // left: -40px;

    //     clip-path: polygon(18% 0, 18% 46%, 24% 73%, 39% 89%, 57% 100%, 68% 82%, 57% 73%, 49% 63%, 50% 48%, 80% 48%, 80% 0);
    //     background: $primary;
    // }
    // &::after {
    //     position: absolute;
    //     content:'';

    //     width: 40px;
    //     height: 40px;

        // top: 40px;
        // left: -70px;

    //     clip-path: polygon(18% 0, 18% 46%, 24% 73%, 39% 89%, 57% 100%, 68% 82%, 57% 73%, 49% 63%, 50% 48%, 80% 48%, 80% 0);
    //     background: $primary;
    // }

    @include phone {
        font-size: 24px;
    }
}

.gallery_content {
    position: relative;
    margin: $mongo 0;
    width: 100%;
    padding: $small;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $mongo;
        
    @include tablet {
        width: 100%;
    }
}

.image_gallery {
    
    width: 100%;

    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    display: flex;
    flex-flow: row nowrap;
    gap: $small;
}

.individual_image {
    min-width: 470px;
    width: 100%;
    height: auto;
    
    scroll-snap-align: center;
    
    @include tablet {
        min-width: 300px;
        width: 100%;
        // min-height: 400px;
    }
}

.media_dewscription{
    padding: 0 $tiny $tiny;
    font-size: 15px;
    font-family: 'Plainlight';
    // font-style: italic;
    color: $alt_hover;

    // border-top: dotted 1px $alt_hover;
    // border-bottom: dotted 1px $alt_hover;
}

.video_content {
    width: 100%;
    height:100%;

    display: flex;
        flex-direction: column;
        gap: $mid 0;
}

.responsive_iframe {
    // position: absolute;
    width: 100%;
    height: 100%;
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');


:root {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    scroll-behavior: smooth;
    height: 100%;

    /* background-image:  radial-gradient( rgb(244, 244, 244), rgb(236, 236, 236)); */

}

@media only screen and (max-width: 768px) {
    :root {
        font-size: 14px;
    }
}
@media only screen and (max-width: 412px) {
    :root {
        font-size: 12px;
    }
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    scroll-behavior: smooth;
    
    
}

*:focus {
    outline: none;
}
*::selection {
    color: white;
    padding: 4px;
    background-color: rgb(64, 166, 255);
}


/* header {
    position: fixed;
    z-index: 10000;
    inset: 0;

    width: 100%;
    height: 120px;

    display: flex;
    flex-direction: column;
        justify-content: center;
        align-items: center;

    background-image: radial-gradient( rgb(236, 236, 236), rgb(255, 255, 255));

    box-shadow: 0 0 16px 3px rgba( 0 0 0 /0.25);

    transition: all .3s;

} */

/* @media only screen and (max-width: 768px) {
    header {
        height: 60px;
    }
} */

h2 {
    font-size: 1.1rem;
    font-family: "Adelle";
    font-weight: 800;
    color: #ff0033;
}

textarea {
    height: 140px;
    resize: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #9e9e9e;
}

a {
    /* padding: 4px; */
    font-size: 18px;
    font-family: 'Plainbold';
    color: #007ce2;
    /* font-weight: 800; */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h2 {
    font-family: 'Plainbold';
    font-size: 23px;
    letter-spacing: -1px;
    color: black;
    color: #006ac2;
}

h3 {
    font-family: 'Plainbold';
    font-size: 18px;
    letter-spacing: -1px;
    color: black;
    color: #006ac2;
}

.link_comun {
    font-size: 16px;
    font-family: 'Plainbold';
    font-weight: 700;
    color: black;
    color: #006ac2;
}

.link_titulo {
    position: relative;
    font-size: 19px;
    font-family: 'Plainbold';
    font-weight: 900;
    color: rgb(173, 72, 0);
    color: #006ac2;
    text-decoration: none;
}