@import './variables.module.scss';
@import './fonts.module.scss';


.form {
    width: 100%;

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $big;
}

.input {
    padding: $mid;

    width: 280px;
    height: 36px;

    font-size: 12px;
    font-family: 'Light';
    color: $black;

    -webkit-appearance: none;
    appearance: none;
    
    border: solid 1px $placeholder;
    border-radius: $small;

    &::placeholder {
        color: $placeholder;
    }

    background-color: $white;

    @include tablet {
        font-size: $mid_text;
    }
}

.small_input {
    width: 60px;
}

.mid_input {
    width: 120px;
}

.big_input {
    width: 320px;
}

.textarea {
    padding: $mid;
    width: 100%;

    resize: none;
    
    font-size: 16px;
    font-family: 'Light';
    color: $black;
    letter-spacing: -1px;

    border-radius: 10px;
    border: none;
}

.big_textarea {
    margin: $small;
    padding: $mid;

    width: 100%;
    height: 320px;

    resize: none;
    
    font-size: 16px;
    font-family: 'Light';
    color: $black;
    letter-spacing: -1px;

    border-radius: 10px;
    border: none;
}


.selector {
    position: relative;
    padding: $small $mid;
    
    width: 56px;
    height: 36px;

    font-size: $mid;
    font-family: 'Regular';
    color: $black;
    
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    // border: none;

    // border-radius : $small;
    border: solid 1px $alt;
}

.big_selector {
    width: 90px;
}

.max_selector {
    width: 130px;
}

.submit {
    cursor: pointer;
    padding: $small $mid;

    height: 36px;

    font-size: 14px;
    font-family: 'Bold';
    color: $white;

    border: none;
    border-radius : $small;
    background-color: $primary;
    
    &:hover {
        background-color: $primary_hover;
    }

    transition: all .3s;

    @include phone {
        font-size: 12px;
    }
}

.small_submit {
    cursor: pointer;
    padding: $small;

    height: 26px;

    font-size: 11px;
    font-family: 'Bold';
    color: $white;

    border: none;
    border-radius : $small;
    background-color: $primary;
    
    &:hover {
        background-color: $primary_hover;
    }

    transition: all .3s;
}

.danger_submit {
    cursor: pointer;
    padding: $small $mid;

    height: 36px;

    font-size: 14px;
    font-family: 'Bold';
    color: $white;

    border: none;
    border-radius : $small;
    background-color: $danger;
    
    &:hover {
        background-color: $primary_hover;
    }

    transition: all .3s;

    @include phone {
        font-size: 12px;
    }
}

.ok_submit {
    cursor: pointer;
    padding: $small $mid;

    height: 36px;

    font-size: 14px;
    font-family: 'Bold';
    color: $white;

    border: none;
    border-radius : $small;
    background-color: $ok;
    
    &:hover {
        background-color: $primary_hover;
    }

    transition: all .3s;

    @include phone {
        font-size: 12px;
    }
}

.cancel {
    cursor: pointer;
    padding: $small $mid;

    height: 36px;

    font-size: 14px;
    font-family: 'Bold';
    color: $primary;

    border: none;
    border-radius : $small;
    background-color: $white;

    &:hover {
        color: $white;
        background-color: $dark_white;
    }

    transition: all .3s;

    @include phone {
        font-size: 12px;
    }
}

.prev_next {
    z-index: $prevnext;
    position: relative;

    width: 36px;

    &::after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;

        top: calc(50% - 5px);

        background: $white;
    }
}

.prev {
    &::after {
        left: calc(50% - 6px);
        clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
    }
}

.next {
    &::after {
        left: calc(50% - 4px);
        clip-path: polygon(0% 0%, 100% 50%, 0 100%);
    }
}

.close_button {
    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;

    margin: 6px;

    width:  26px;
    height: 26px;

    &::before {
        position: absolute;
        content: '';
        top: 5px;
        left: 11px;
        
        width: 4px;
        height: 16px;
        background: $black;

        transform: rotate(45deg);
    }
    &::after {
        position: absolute;
        content: '';
        top: 5px;
        left: 11px;
        
        width: 4px;
        height: 16px;
        background: $black;

        transform: rotate(135deg);
    }

    border-radius: 50%;
    background: $primary;
}

input::-webkit-datetime-edit { padding: $mid }
input::-webkit-datetime-edit { border-radius: $mid }
input::-webkit-datetime-edit-fields-wrapper { background: none; }
input::-webkit-datetime-edit-text { color: $primary; padding: 0 $small; }
input::-webkit-inner-spin-button { display: none; }
input::-webkit-calendar-picker-indicator { background: orange; }

input::-moz-datetime-edit { padding: $mid }
input::-moz-datetime-edit { border-radius: $mid }
input::-moz-datetime-edit-fields-wrapper { background: none; }
input::-moz-datetime-edit-text { color: $primary; padding: 0 $small; }
input::-moz-inner-spin-button { display: none; }
input::-moz-calendar-picker-indicator { background: orange; }
