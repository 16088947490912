@import '../../styles/main.module.scss';

.main_container {
    padding: 30px;

    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        
        background: $dark_black;
        // border: dotted 5px red;
        
        @include phone {
            padding: $tiny;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
    }
}

.side_bar {
    width: fit-content;
    height: 500px;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

    border-radius: $mid;

    background: $black;
    box-shadow: $tiny_shadow;

    @include phone {
        height: fit-content;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
}

.sidebar_button_container {
    cursor: pointer;
    padding: 20px;

    width: 100%;

    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
    transition: all .2s;

    &:hover {
        color: $white;
        background: $dark_black;
    }

    &:first-child {
        border-top-left-radius: $mid;
        border-top-right-radius: $mid;
    }

    @include phone {
        width: 55px;
        &:first-child {
            border-top-left-radius: $mid;
            border-top-right-radius: 0;
            border-bottom-left-radius: $mid;
        }
        &:last-child {
            border-top-right-radius: $mid;
            border-bottom-right-radius: $mid;
        }

        // border-top-left-radius: $mid;
        // border-bottom-left-radius: $mid;
    }

}

.sidebar_button {
    width: 100%;

    font-size: 15px;
    font-family: 'Light';
    color: #999;

    display: flex;
    flex-direction: row;
    align-items: center;

    transition: all .3s;

    &:hover {
        color: white;
    }

    @include tablet {
        display: none;
        transition: all .3s;
    }
}

.black {
    background: $dark_black;
}

.content {
    padding: 0 $big;
    width: 100%;

    @include tablet {
        padding: 0 $tiny;
    }
}