@import '../styles/main.module.scss';

.blocks_container {
    // margin: 0 $mid 0; 
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    place-content: center;

    // display: flex;
    //     flex-flow: row wrap;
    //     justify-content: flex-start;
    //     align-items: center;
        gap: 30px;

        // @include tablet {
        //     gap: 10px;
        // }
}

.block {
    cursor: pointer;
    // cursor: default;

    // max-width: 260px;
    width: 100%;
    height: fit-content;
    min-height: 120px;

    padding: $big;

    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    gap: 14px;

    font-size: 18px;
    font-family: 'Light';
    letter-spacing: -0.5px;
    color: $white;

    border-radius: $big;
    background-color: $black;

    box-shadow: $tiny_shadow;

    &:hover {
        transform: scale(1.02);
    }
    transition: ease all .3s;
}

.block_subtitle {
    margin-top: 16px;
    padding-top: 16px;

    font-size: 22px;
    font-family: 'Regular';
    letter-spacing: -1px;
    
}