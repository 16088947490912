@import '../../styles//main.module.scss';


.input_upload {
    display: none;
}


// .uploadLabel {
//     cursor: pointer;
//     width:  70px;
//     height: 70px;

//     display: flex;
//         flex-direction: grid;
//         place-content: center;

//     font-size: 46px;
//     color: $primary;
//     border: solid 2px $primary;
//     background-color: none;

//     background-position: center;
//     background-size: cover;
//     background-repeat: no-repeat;

//     &:hover {
//         font-size: 40px;
//         border: solid 6px rgb(255, 0, 81);
//     }
// }

// .delete_button {
//     font-weight: 900;
//     color: $primary_hover;

//     &:hover {
//         font-size: 40px;
//         border: solid 6px rgb(255, 0, 81);
//     }
// }


.uploadLabel {
    cursor: pointer;
    width:  110px;
    height: 110px;

    display: flex;
        flex-direction: grid;
        place-content: center;

    font-size: 70px;
    color: rgb(255, 0, 81);
    border: solid 2px $primary;
    background-color: none;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:hover {
        font-size: 66px;
        border: solid 4px rgb(255, 0, 81);
    }
}

.delete_button {
    font-weight: 900;
    color: rgb(255, 0, 81);

    &:hover {
        font-size: 66px;
        border: solid 4px rgb(255, 0, 81);
    }
}
