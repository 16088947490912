@import '../publications.module.scss';

.main_container {
    padding: $god;
    
    @include tablet {
        padding: $mongo;
    }
    @include phone {
        padding: $big;
    }
}

.blocks_container {
    margin: $big;
    padding: $mongo; 

    width: 100%;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;

    border-radius: $big;
    background-color: $black;

    box-shadow: $tiny_shadow;

    @include tablet {
        padding: $ultra;
    }
    @include phone {
        padding: $big;
    }
}

.blocks_container_buttons {
    margin: $mid 0;
    padding: $big; 

    width: 100%;

    display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;

    border-radius: $big;
    background-color: $black;

    box-shadow: $tiny_shadow;

    // @include tablet {
    //     justify-content: center;
    // }
}

.blocks_container_popups {
    margin: $mid 0;
    padding: $mid;

    // width: 100%;

    display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: $big;

    border-radius: $big;
    // background-color: $black;

    // box-shadow: $tiny_shadow;
}

.popups_container {
    display: flex;
    flex-flow: row wrap;
}

.popups {
    display: flex;
    flex-flow: row wrap;
}

.block {
    cursor: pointer;

    max-width: 260px;
    width: 100%;
    height: fit-content;

    padding: $big;

    display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 14px;

    font-size: 18px;
    font-family: 'Light';
    letter-spacing: -0.5px;
    color: $white;

    border-radius: $big;
    background-color: $black;

    box-shadow: $tiny_shadow;
}

.info {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    // display: flex;
    //     flex-flow: row wrap;
    //     justify-content: space-between;
    // align-items: center;
}

.date_info {
    width: 100%;

    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        gap: $mongo;
}

.input_group {
    width: 100%;

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
}

.input {
    width: 100%;
    
    font-size: 16px;
    font-family: 'Ultralight';
    color: $black;
    letter-spacing: -1px;

    border-radius: 10px;
}

.paragraph_divisor {
    padding: $big 0;

    width: 100%;
    
    border-top: solid 1px $placeholder;
}

.main_label {
    padding-left: 8px;
    font-size: 18px;
    font-family: 'Bold';
    letter-spacing: -1px;
}

.alt_label {
    padding: 8px;

    font-size: 16px;
    font-family: 'light';
    letter-spacing: -1px;
}

.edit_buttons {
    flex-direction: row;
    justify-content:  flex-end;
}

.main_text_editor_container {
    margin: 20px 0;
    width: 100%;
}

.text_editor_paragraph_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.image_gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: $mid;
}

.title_and_button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.media_gallery {
    width: 100%;
    padding: $big;

    display: flex;
        flex-direction: column;
        gap: $small;
    
    // border-top: dotted 1px $placeholder;
    // border-bottom: dotted 1px $placeholder;
    border-radius: $mid;

    // background-color: black;
}

.media_gallery_title_button {
    padding: $mid 0;
    width: 100%;

    display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

}

.media_content {
    padding: $mid 0;
    // border-bottom: solid 4px grey;
    
    display: flex;
        flex-direction: column;
        gap:16px;
}

.spotify_form {
    width: 100%;

    display: flex;
        flex-direction: column;
        justify-content: flex-center;
        align-items: flex-start;
        gap: $mid;
}

.responsive_iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}

.modal_edition {
    overflow-y: scroll;
}

.modal_edition_content {
    overflow-y: scroll;
    width: 700px;
    
    @include tablet {
        width: 90%;
    }
}

.media_block {
    cursor: pointer;

    // max-width: 260px;
    width: 100%;
    height: fit-content;

    padding: $big;

    display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 14px;

    font-size: 18px;
    font-family: 'Light';
    letter-spacing: -0.5px;
    color: $white;

    border-radius: $big;
    background-color: $black;

    box-shadow: $tiny_shadow;
}

.media_textarea {
    padding: $mid;
    width: 100%;
    height: 80px;

    resize: none;
    
    font-size: 16px;
    font-family: 'Light';
    color: $black;
    letter-spacing: -1px;

    border-radius: 10px;
    border: none;
}