@import '../sections.module.scss';

.dual_data {
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 140px;
        // border:solid 2px green;
}
.data_circle {
    position: relative;

}

.data {
    position: absolute;
    width:  90px;
    height: 90px;
    top: 35px;
    left: 30px;
    top:0;
    left:0;

    display: grid;
    place-content: center;
}

.block {
    cursor: default;

    min-height: 200px;
    flex-flow: row wrap;
    justify-content: space-evenly;

    &:hover {
        transform: scale(1);
    }
}

.circle_and_bars {
    display: flex;
    flex-direction: row;
}

.bar_graph {
    position:'relative';
    padding: $mid;
    // height: 200px;
    // background-color: rgba(255, 255, 255, 0.07);
    /* border-left: solid 4px rgba(150 150 150/.8);
    border-bottom: solid 4px rgba(150 150 150/.8); */
    // border-left:   dotted 1px rgb(80, 80, 80);
    border-bottom: dotted 1px rgb(80, 80, 80);
    
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    gap:14px

}

.month_day_bar {
    // position: relative;
    margin: 6px;
    width: 38px;

    display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;
}

.month_day_popup {
    position: absolute;
    z-index: 1000;

    padding: $mid;
    min-width: 240px;
    width: fit-content;
    // height: 140px;
    
    // top: -90px;

    border-radius: 6px;
    border: solid 1px $placeholder;
    background-color: $white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: $small_shadow;

    &::after {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        top: 56px;
        background: red;
        transform: rotate(45deg);
        border-right: solid 1px $placeholder;
        border-bottom: solid 1px $placeholder;
        background-color: $white;
    }
}


.key_text {
    font-size: 16px;
    font-family: 'Regular';
    color: $alt_hover;

}

.value_text {
    font-size: 15px;
    font-family: 'Light';
    text-align: center;
    color: $black;

    span {
        font-size: 18px;
        font-family: 'Bold';
        color: $primary;
    }
}

.bar {
    margin: 3px;
    width: 100%;
}

.key {
    font-size: 14px;
    font-family: 'Ultralight';
    // font-weight: 100;
    color: white;
}
.value {
    font-size: 15px;
    font-family: 'Bold';
    // font-weight: 100;
    color: white;
}