@import '../sections.module.scss';


.sections_list {
    width: 100%;
    
    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
}

.section_modal_edition {
    position: fixed;
    
    top:0;
    left: 0;


    width: 100%;
    height: 100%;

    display: flex;
        justify-content: center;
        align-items: center;

        background: 'red';
}

.on {
    visibility: visible;
    opacity: 1;
    
    transition: all .3s;
}

.off {
    visibility: hidden;
    opacity: 0;
    
    transition: all .3s;
}

.on_content {
    top: 0;
    transition: all .3s;
}

.off_content {
    top: -40px;
    transition: all .3s;
}


.close_button {
    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;

    margin: 6px;

    width:  26px;
    height: 26px;

    &::before {
        position: absolute;
        content: '';
        top: 5px;
        left: 11px;
        
        width: 4px;
        height: 16px;
        background: $black;

        transform: rotate(45deg);
    }
    &::after {
        position: absolute;
        content: '';
        top: 5px;
        left: 11px;
        
        width: 4px;
        height: 16px;
        background: $black;

        transform: rotate(135deg);
    }

    border-radius: 50%;
    background: $primary;
}