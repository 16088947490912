@import '../../styles/main.module.scss';

.footer {
    width: 100vw;
    padding: $big;
    height: fit-content;
    background: $black;

    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        // border: solid 2px white;
}

.footer_text {
    font-family: 'Light';
    font-size: 14px;
    color: #bbbbbb;
    text-align: center;
}