@import './animations.module.scss';
@import './media.module.scss';
@import './variables.module.scss';
@import './fonts.module.scss';
@import './inputs.module.scss';

:root {
    font-family: 'Regular';
    font-size: 16px;
    scroll-behavior: smooth;

    background-image: $primary_background;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    scroll-behavior: smooth;
    
    &:focus {
        outline: none;
    }

    &::selection {
        color: $white;
        padding: $tiny;
        background-color: $primary;
    }
}

a {
    font-size: $big;
    font-family: 'Bold';
    font-style: italic;
    color: $primary;
}

.main_container {
    width: 100vw;
    min-height: 100vh;

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    background: $primary_radial_gradient_background;
}

.title_container {
    width: 100%;
    padding: $mid 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    border-bottom: dotted 1px $placeholder;
}

.main_title {
    font-size: $hiper_text;
    font-family: "Bold";
    letter-spacing: -1px;
}

.main_subtitle {    
    font-size: $hiper_text;
    font-family: "Light";
    letter-spacing: -1px;
}

.title {
    font-size: $ultra_text;
    font-family: 'Bold';
    letter-spacing: -1px;
}

.subtitle {
    font-size: $ultra_text;
    font-family: 'Regular';
    letter-spacing: -1px;
}

.light_subtitle {    
    font-size: $ultra_text;
    font-family: "Ultralight";
    letter-spacing: -1px;
}

.mega_bold_text {
    font-size: $mega_text;
    font-family: 'Bold';
    letter-spacing: -0.5px;
}

.mega_text {
    font-size: $mega_text;
    font-family: 'Regular';
    letter-spacing: -0.5px;
}

.mega_light_text {
    font-size: $mega_text;
    font-family: 'Light';
    letter-spacing: -0.5px;
}

.big_bold_text {
    font-size: $big_text;
    font-family: 'Bold';
    letter-spacing: -0.5px;
}

.big_text {
    font-size: $big_text;
    font-family: 'Regular';
    letter-spacing: -0.5px;
}

.big_light_text {
    font-size: $big_text;
    font-family: 'Light';
    letter-spacing: -0.5px;
}

.bold_text {
    font-size: $text;
    font-family: 'Bold';
    letter-spacing: -0.5px;
}

.text {
    font-size: $text;
    font-family: 'Regular';
    letter-spacing: -0.5px;
}

.light_text {
    font-size: $text;
    font-family: 'Light';
    letter-spacing: -0.5px;
}

.mid_bold_text {
    font-size: $mid_text;
    font-family: 'Bold';
    letter-spacing: -0.5px;
}

.mid_text {
    font-size: $mid_text;
    font-family: 'Regular';
    letter-spacing: -0.5px;
}

.mid_light_text {
    font-size: $mid_text;
    font-family: 'Light';
    letter-spacing: -0.5px;
}

.small_bold_text {
    font-size: $small_text;
    font-family: 'Bold';
    letter-spacing: -0.5px;
}

.small_text {
    font-size: $small_text;
    font-family: 'Regular';
    letter-spacing: -0.5px;
}

.small_light_text {
    font-size: $small_text;
    font-family: 'Light';
    letter-spacing: -0.5px;
}

.modal_main_container {

    z-index: $modal_main_container;
    
    position: fixed;
    top: 0;
    left: 0;
    
    padding: $big;

    width: 100%;
    height: 100%;

    display: flex;
        justify-content: center;
        align-items: center;
}

.modal_block {
    position: relative;
    width: 340px;
    height: 170px;

    padding: $big;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: solid 1px $light_black;
    border-radius: $mid;
    background: $black;
}

.modal_edition {
    position: fixed;
    
    top:0;
    left: 0;


    width: 100%;
    height: 100%;

    display: flex;
        justify-content: center;
        align-items: center;
}

.modal_edition_content {
    position: relative;
    padding: $mega;

    width: 450px;
    // min-height: 200px;

    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $big;

    border-radius: $big;
    background: $dark_black;
    box-shadow: $dark_shadow;
}

.on {
    visibility: visible;
    opacity: 1;
    
    transition: all .3s;
}

.off {
    visibility: hidden;
    opacity: 0;
    
    transition: all .3s;
}

.on_content {
    top: 0;
    transition: all .3s;
}

.off_content {
    top: -40px;
    transition: all .3s;
}

.modal_openclose_buttons {
    padding-top: 30px;
    

    display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        gap: 28px;
}

.imagen {
    width: 100%;

    border-radius: 6px;
}

.epigrafe {
    padding-bottom: 18px;
    font-size: 15px;
    font-family: 'Roboto';
    font-style: italic;
    color: #007ce2;

    border-top: dotted 1px #007ce2;
    border-bottom: dotted 1px #007ce2;
}