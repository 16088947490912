@mixin smallphone{
    @media only screen and (max-width: 350px) {
        @content;
    }
}

@mixin phone{
    @media only screen and (max-width: 480px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 820px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}
