@import '../../styles/main.module.scss';

.ring_container {
    transform-origin: 50%;
    transform: scale(1) rotate(-90deg);
  }

  .ring_background {
    fill: none;
    transform: translateX(50%) translateY(50%);
    opacity: 0.25;
  }
  .ring_completed {
    fill: none;
    transform: translateX(50%) translateY(50%);
    stroke-linecap: round;
  }