@import '../../list.module.scss';

.main_row_content {
    padding: $mid;
    width: 100%;

    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $big;
}

.thumbnail {
    min-width: 40px;
    height: 30px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    border: solid 1px white;
    border-radius: $tiny;
}

.pub_row {
    width: 100%;

    display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        gap: $big;
        
    @include tablet {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

.pub_id {
    padding: $tiny;
    min-width: 32px;

    height: fit-content;

    color: $white;
    text-align: center;

    
    border-radius: $tiny;
}

.pub_title {
    // width: 100%;
    // height: 26px;
    width: 420px;
    overflow: scroll;
    
    @include tablet {
        width: 320px;
    }

    @include phone {
        width: 200px;
    }
}

.pub_date_editor {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $mid;
    
    @include tablet {
        display: none;
    }
}

.pub_data {
    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        gap: $mid;

    @include tablet {
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }

}

.pub_icons {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $tiny;

}

.id_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $mid;
}