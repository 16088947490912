@import '../../styles/main.module.scss';

.loader_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: grid;
    place-content: center;

    background: rgba(0 0 0 /0.8);
}

.loader {
    position: relative;
    border: solid 5px rgb(195 195 195 /.4);
    border-radius: 50%;
    border-top: solid 6px $primary;
    animation-name: rotate_animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
