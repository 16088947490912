@import '../sections.module.scss';


.sections_list {
    width: 100%;
    
    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
}

.on {
    visibility: visible;
    opacity: 1;
    
    transition: all .3s;
}

.off {
    visibility: hidden;
    opacity: 0;
    
    transition: all .3s;
}
