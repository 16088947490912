@import '../publications.module.scss';


.total_and_pagination {
    padding: $mid;
    width: 100%;

    font-size: 16px;
    font-family: 'Regular';

    // display: grid;
    // place-content: center;
    // grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    // place-content: center;
    display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        gap: $mid;

    border-radius: $small;
}

.search_elements {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $small;
}


.pagination_buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $small;
}

.results {
    width: 100%;

    display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        gap: $big;
}

.pagination_per_page {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
}

.pagination_pages {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
}

.pubs_list {
    width: 100%;
    
    display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
}
